import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from '@app/core/services/api.service';
import * as moment from 'moment';
import {Devices, DeviceTelemetry} from '@shared/models/device.model';
import {concatMap, map, reduce} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThingsboardApiService {

  data = [];
  timeFormat = 'DD/MM/YYYY HH:mm:ss';

  logoPng = new Image(); // Создает новый элемент изображения
  legendPNG = new Image(); // Создает новый элемент изображения

  deviceId = '';

  constructor(
    private api: ApiService,
  ) {
    this.logoPng.src = '/img/logo.png'; // Устанавливает путь
    this.legendPNG.src = '/img/legend_motors_chart.png'; // Устанавливает путь

  }

  getDeviceTelemetry(keys, deviceId) {
    const keysString = keys.join(',');
    const params = {
      keys: keysString,
      // startTs: startTimestamp,
      // endTs: endTimestamp,
      // agg
    };

    return this.api.get(`/plugins/telemetry/DEVICE/${deviceId}/values/timeseries`, params);
  }

  getDeviceTelemetryControl(keys, deviceId) {
    const keysString = keys.join(',');
    const params = {
      keys: keysString,
      // startTs: startTimestamp,
      // endTs: endTimestamp,
      // agg
    };

    return this.api.get(`/plugins/telemetry/DEVICE/${deviceId}/values/attributes`, params).pipe(
      concatMap(data => {
        return of(data.reduce((ret, item: any) => ({...ret, [item.key]: item.value}), {}));
      })
    );
  }

  getDeviceTelemetries(startTimestamp, endTimestamp, agg, keys, deviceId, interval): Observable<DeviceTelemetry> {
    const keysString = keys.join(',');
    const params = {
      interval: Math.round((endTimestamp - startTimestamp) / interval),
      limit: 700,
      keys: keysString,
      startTs: startTimestamp,
      endTs: endTimestamp,
      agg
    };

    return this.api.get(`/plugins/telemetry/DEVICE/${deviceId}/values/timeseries`, params);
  }

  getUserDevices(customerId): Observable<Devices> {
    const params = {
      pageSize: 100,
      page: 0
    };
    return this.api.get(`/customer/${customerId}/devices`, params);
  }

  getUserDeviceGroupList(): Observable<any> {
    const params = {
      pageSize: 100,
      page: 0,
      sortProperty: 'createdTime',
      sortOrder: 'DESC'
    };
    return this.api.get(`/entityGroups/DEVICE`, params);
  }

  apiGetEntityGroupsForEntity(entityType, entityId): Observable<any> {
    return this.api.get(`/entityGroups/${entityType}/${entityId}`);
  }

  apiGetDeviceGroupAttributes(entityId, entityType, scope): Observable<any> {
    const params = {
      pageSize: 100,
      page: 0,
      sortProperty: 'createdTime',
      sortOrder: 'DESC'
    };
    return this.api.get(`/plugins/telemetry/${entityType}/${entityId}/values/attributes/${scope}`, params);
  }

  apiCreateDevicesGroup(nameOfGroup): Observable<string> {
    const params = {
      name: nameOfGroup,
      type: 'DEVICE'
    };

    return this.api.post(`/entityGroup`, params).pipe(
      map(groupInfo => groupInfo.id.id)
    );
  }

  apiAddDevicesToGroup(groupId, listOfDevices): Observable<any> {
    return this.api.post(`/entityGroup/${groupId}/addEntities`, listOfDevices);
  }

  apiGetDevicesOfGroup(groupId): Observable<any> {
    const params = {
      pageSize: 100,
      page: 0,
      sortProperty: 'createdTime',
      sortOrder: 'DESC'
    };
    return this.api.get(`/entityGroup/${groupId}/devices`, params);
  }

  apiUpdateParametersToGroup(groupId, attributes): Observable<any> {
    return this.api.post(`/plugins/telemetry/ENTITY_GROUP/${groupId}/SERVER_SCOPE`, attributes);
  }

  apiUpdateParametersToDevice(deviceId, attributes): Observable<any> {
    return this.api.post(`/plugins/telemetry/DEVICE/${deviceId}/SERVER_SCOPE`, attributes);
  }

  apiGetMyRelations(): Observable<any> {
    const params = {
      method: 'getMyRelations'
    };
    return this.api.post(`/rule-engine/`, params);
  }

  // {
  //     "method": "addRelation",
  //     "additionalInfo": {
  //  	    "name": "",
  //             "permission": "write"
  // 	},
  //     "to": {
  //         "email": "andrik456123@gmail.com"
  //     }
  // }

  apiAddRelation(email: string, permission: string = 'read'): Observable<any> {
    const params = {
      method: 'addRelation',
      additionalInfo: {
        permission
      },
      to: {
        email
      }
    };
    return this.api.post(`/rule-engine/`, params);
  }


  // {
  //     "method": "updateRelation",
  //     "additionalInfo": {
  //  	    "name": "newRelation"
  // 	},
  //     "to": {
  //         "email": "andrik456123@gmail.com"
  //     }
  // }

  apiUpdateRelation(email: string, name: string): Observable<any> {
    const params = {
      method: 'updateRelation',
      additionalInfo: {
        name
      },
      to: {
        email
      }
    };
    return this.api.post(`/rule-engine/`, params);
  }

  // {
  //     "method": "approveRelation",
  //     "additionalInfo": {
  //  	    "name": "newRelation"
  // 	},
  //     "to": {
  //         "email": "andrik456123@gmail.com"
  //     }
  // }

  apiAcceptRelation(email: string, name: string): Observable<any> {
    const params = {
      method: 'approveRelation',
      additionalInfo: {
        name
      },
      to: {
        email
      }
    };
    return this.api.post(`/rule-engine/`, params);
  }

  apiRejectRelation(email: string): Observable<any> {
    const params = {
      method: 'rejectRelation',
      to: {
        email
      }
    };
    return this.api.post(`/rule-engine/`, params);
  }

  apiDeleteRelation(email: string): Observable<any> {
    const params = {
      method: 'deleteRelation',
      to: {
        email
      }
    };
    return this.api.post(`/rule-engine/`, params);
  }
}
