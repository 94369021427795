<div class="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
  <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">

    <div class="info-card-text">
      <div class="fs-lg text-truncate text-truncate-lg user-name-label">{{ (profile$ | async).firstName }} {{ (profile$ | async).lastName }}</div>
      <span class="text-truncate text-truncate-md opacity-80 user-email-label">{{ (profile$ | async).email }}</span>
    </div>
  </div>
</div>
<div class="dropdown-divider m-0"></div>
<div class="dropdown-multilevel dropdown-multilevel-left">
  <div class="dropdown-item">
    {{'Language' | i18next}}
  </div>

  <div class="dropdown-menu">
    <div *ngFor="let lang of languages">
      <a *ngIf="language !== lang" href="?lng={{lang}}" class="link lang-item dropdown-item {{lang}}"
         (click)="changeLanguage(lang)">
        <span *ngIf="lang == 'ua'">Українська</span><span *ngIf="lang == 'ru'">Русский</span>
        <span *ngIf="lang == 'en'">English (US)</span>
      </a>
      <span *ngIf="language === lang" class="current lang-item dropdown-item active {{lang}}">
        <span *ngIf="lang == 'ua'">Українська</span><span *ngIf="lang == 'ru'">Русский</span>
        <span *ngIf="lang == 'en'">English (US)</span></span>
    </div>
  </div>
</div>
<div class="dropdown-divider m-0"></div>
<a class="dropdown-item fw-500 pt-3 pb-3" [routerLink]="['/auth/logout']">
  <span data-i18n="drpdwn.page-logout">{{'Logout' | i18next}}</span>
</a>
