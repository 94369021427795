import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sa-color-profile-reference',
  templateUrl: './color-profile-reference.component.html',
  styleUrls: ['./color-profile-reference.component.scss']
})
export class ColorProfileReferenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
