<!-- BEGIN Page Wrapper -->
<div class="page-wrapper">

  <div class="page-inner">

    <aside sa-left-panel class="page-sidebar"></aside>

    <div class="page-content-wrapper">

      <sa-header></sa-header>

      <!-- BEGIN Page Content -->
      <!-- the #js-page-content id is needed for some plugins to initialize -->
      <main id="js-page-content" role="main" class="page-content" [@routerTransition]="getState(o)">
        <router-outlet #o="outlet"></router-outlet>
      </main>

      <sa-content-overlay></sa-content-overlay>

      <!-- END Page Content -->

      <footer sa-footer class="page-footer" role="contentinfo"></footer>

      <sa-shortcut-modal></sa-shortcut-modal>

      <sa-color-profile-reference></sa-color-profile-reference>

    </div>

  </div>

</div>
<!-- END Page Wrapper -->
