import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as actions from './auth.actions';

import {tap, filter, switchMap, map, catchError, exhaustMap} from 'rxjs/operators';

// import { fireApp, fireAuth } from '../../firebase';
import {AuthState} from './auth.reducer';
import {Store} from '@ngrx/store';
import {Router, ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {AuthService, StorageService} from '@app/core/services';
import {AuthDto, AuthResponseDto, UserResponseDto} from '@shared/models/auth.dto';
import {AuthActionTypes, AuthFailure, AuthTokenPayload, AuthUserChange} from './auth.actions';
import {of} from 'rxjs';

// const auth = fireApp.auth();

@Injectable()
export class AuthEffects {
  redirectUrl = '/dashboard';
  loginUrl = '/auth';

  @Effect()
  login$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginAction),
    switchMap((credentials: any) => {
        return this.authService.login(credentials.payload as AuthDto).pipe(
          // map(tokens => ({type: actions.AuthTokenPayload, payload: tokens})),
          map(tokens => new AuthTokenPayload(tokens)),
          catchError(error => of(new AuthFailure({error})))
        );
      }
    )
  );

  @Effect()
  appinit$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AppInit),
    tap((_) => {
        // if ()
        // return this.authService.login(credentials.payload as AuthDto).pipe(
        //   // map(tokens => ({type: actions.AuthTokenPayload, payload: tokens})),
        //   map(tokens => new AuthTokenPayload(tokens)),
        //   catchError(error => of(new AuthFailure({error})))
        // );
      }
    )
  );

  @Effect({dispatch: false})
  logout$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LogoutAction),
    tap((action: any) => {
      console.log('AuthActionTypes.LogoutAction', action.redirect);

      this.authService.clearTokens();

      if (action.redirect) {
        this.router.navigate([this.loginUrl]);
      }
    })
  );

  @Effect({dispatch: false})
  signup$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.SignupAction),
    tap((data: any) => {
      // auth
      //   .createUserWithEmailAndPassword(
      //     data.payload.username,
      //     data.payload.password
      //   )
      //   .catch(this.dispatchError);
    })
  );

  @Effect({dispatch: false})
  googleSign$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.GoogleSign),
    tap((data: any) => {
      // auth
      //   .signInWithPopup(new fireAuth.GoogleAuthProvider())
      //   .catch(this.dispatchError);
    })
  );

  @Effect({dispatch: false})
  loginRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginRedirect),
    tap((data: any) => {
      console.log('LoginRedirect', data);
      this.redirectUrl = data.payload || '';
      this.router.navigate([this.loginUrl]);
    })
  );

  @Effect()
  authRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthTokenPayload),
    tap((data: any) => {
      console.log('AuthTokenPayload', data);
    }),
    switchMap((tokens: any) => {
      this.authService.setTokens(tokens.payload);

      return this.authService.getUser().pipe(
        map(payload => new AuthUserChange(payload)),
        catchError(error => of(new AuthFailure({error})))
      );
    }),
  );

  @Effect({dispatch: false})
  authUser$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthUserChange),
    tap((data: any) => {
      console.log('AuthUserChange', this.router.url, this.loginUrl);
    }),
    filter(_ => this.router.url.includes(this.loginUrl)),
    tap((data: any) => {
      console.log('redirect to dashboard', this.redirectUrl);
      this.router.navigate([this.redirectUrl]);
    })
  );

  dispatchError = err => {
    this.store.dispatch(
      new actions.AuthFailure({
        code: err.code,
        message: err.message
      })
    );
  };

  constructor(
    private actions$: Actions,
    private store: Store<AuthState>,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storage: StorageService,
  ) {
    // auth.onAuthStateChanged(data => {
    //   // console.log('\n\n onAuthStateChanged', data);
    // });

    // auth.onIdTokenChanged(authUser => {
    //   // console.log('\n\n onIdTokenChanged', data);
    //   if (authUser) {
    //     this.store.dispatch(new actions.AuthUserChange(authUser));
    //   } else {
    //     this.authToken.token = null;
    //     this.store.dispatch(new actions.NullToken());
    //   }
    // });


  }
}
