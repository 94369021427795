import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRoute, CanActivateChild, Router, CanLoad, Route, UrlSegment} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, reduce, take} from 'rxjs/operators';

import * as fromAuth from '../store/auth';

@Injectable()
export class UnAuthGuard implements CanLoad {
  constructor(
    private router: Router,
    private store: Store<fromAuth.AuthState>) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {

    return this.store.pipe(
      select(fromAuth.getLoggedIn),
      map(authed => {
        console.log('UnAuthGuard', !authed);
        if (!authed) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
          return false;
        }
      }),
      take(1)
    );
  }

}
