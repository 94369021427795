import {Action} from '@ngrx/store';
import {DeviceActions, DeviceActionTypes} from './device.actions';
import {Device, Devices, DeviceTelemetry} from '@shared/models/device.model';

export interface DeviceState {
  devices: Devices;
  devices_loading: boolean;
  devices_error: any;
  currentDevice: Device;
  deviceTelemetry: DeviceTelemetry;
}

export const deviceInitialState: DeviceState = {
  devices: null,
  devices_loading: false,
  devices_error: false,
  currentDevice: null,
  deviceTelemetry: null
};

export function deviceReducer(
  state = deviceInitialState,
  action: DeviceActions
): DeviceState {
  console.log('deviceReducer', action);

  switch (action.type) {
    case DeviceActionTypes.DeviceInit:
      return {
        ...state,
        devices_loading: true
      };

    case DeviceActionTypes.DeviceInitSuccess:
      return {
        ...state,
        devices_loading: false,
        devices_error: null,
        devices: action.payload,
      };

    case DeviceActionTypes.DeviceInitFailure:
      return {
        ...state,
        devices_loading: action.payload,
        devices_error: false,
        devices: null,
      };

    case DeviceActionTypes.DeviceChangeAction:
      return {
        ...state,
        devices_loading: false,
        devices_error: null,
        currentDevice: action.payload,
      };


    default:
      return state;
  }
}
