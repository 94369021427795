import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Profile} from '@shared/models/profile.model';
import {Store} from '@ngrx/store';

import * as fromProfile from '@app/core/store/profile';

@Component({
  selector: 'sa-nav-info-card',
  templateUrl: './nav-info-card.component.html',
  styleUrls: ['./nav-info-card.component.scss']
})
export class NavInfoCardComponent implements OnInit {

  profile$: Observable<Profile>;

  iconPrefix = 'fal';

  constructor(private store$: Store<fromProfile.ProfileState> ) { }

  ngOnInit(): void {
    this.profile$ = this.store$.select(fromProfile.getProfileModel);
  }

}
