import * as fromDevice from './device.reducer';
import {createSelector, createFeatureSelector} from '@ngrx/store';

export const getDeviceState = createFeatureSelector<fromDevice.DeviceState>('device');

export const getDeviceLoading = createSelector(getDeviceState, (state: fromDevice.DeviceState) => state.devices_loading);
// export const getDeviceError = createSelector(getDeviceState, (state: fromDevice.AuthState) => state.error);
export const getDevices = createSelector(getDeviceState, (state: fromDevice.DeviceState) => state.devices);
export const getCurrentDevice = createSelector(getDeviceState, (state: fromDevice.DeviceState) => state.currentDevice);

