import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { SpeechButtonComponent } from "./speech-button/speech-button.component";
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';

@NgModule({
  imports: [CommonModule, ModalModule, PopoverModule],
  exports: [SpeechButtonComponent],
  declarations: [SpeechButtonComponent],
  providers: []
})
export class VoiceControlModule {}
