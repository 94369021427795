<header class="page-header" role="banner">

  <!-- we need this logo when user switches to nav-function-top -->
  <sa-logo></sa-logo>

  <!-- DOC: nav menu layout change shortcut -->
  <div class="hidden-md-down dropdown-icon-menu position-relative">
    <a href="#" class="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden"
       title="{{'Hide Navigation' | i18next}}">
      <i class="ni ni-menu"></i>
    </a>
    <ul>
      <li>
        <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-minify"
           title="{{'Minify Navigation' | i18next}}">
          <i class="ni ni-minify-nav"></i>
        </a>
      </li>
      <li>
        <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed"
           title="{{'Lock Navigation' | i18next}}">
          <i class="ni ni-lock-nav"></i>
        </a>
      </li>
    </ul>
  </div>

  <!-- DOC: mobile button appears during mobile width -->
  <div class="hidden-lg-up">
    <a href="#" class="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
      <i class="ni ni-menu"></i>
    </a>
  </div>


  <div class="ml-auto d-flex">

    <!-- activate app search icon (mobile) -->
    <div class="hidden-sm-up">
      <a href="#" class="header-icon" data-action="toggle" data-class="mobile-search-on" data-focus="search-field"
         title="Search">
        <i class="{{iconPrefix}} fa-search"></i>
      </a>
    </div>

    <!-- app settings -->
    <div class="hidden-md-down">
      <a href="#" class="header-icon" data-toggle="modal" data-target=".js-modal-settings">
        <i class="{{iconPrefix}} fa-cog"></i>
      </a>
    </div>

    <!-- app user menu -->
    <div>
      <a href="#" data-toggle="dropdown" title="{{email}}"
         class="header-icon d-flex align-items-center justify-content-center ml-2">
        <i class="profile-image rounded-circle fal fa-user-circle"></i>
        <!-- you can also add username next to the avatar with the codes below:
        <span class="ml-1 mr-1 text-truncate text-truncate-header hidden-xs-down">Me</span>
        <i class="ni ni-chevron-down hidden-xs-down"></i> -->
      </a>
      <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
        <app-dropdown-menu></app-dropdown-menu>
      </div>
    </div>

  </div>

</header>
