export const environment = {
  production: true,

  firebase: {
  },

  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },

  recaptcha: {
    siteKey: '6LeTPzsaAAAAADX6Kvi9z2s6oDXyOUx8UWPShmhl',
  }

};
