import {Action} from '@ngrx/store';
import {Device, Devices} from '@shared/models/device.model';

export enum DeviceActionTypes {
  AppInit = '[App] Init',
  DeviceInit = '[Device] Init',
  DeviceInitSuccess = '[Device] Init Success',
  DeviceInitFailure = '[Device] Init Failure',
  DeviceChangeAction = '[Device] Device Change Action',
  DeviceChangeSuccess = '[Device] Success Action',
  DeviceChangeFailure = '[Device] Failure Action',
}

export class AppInit implements Action {
  readonly type = DeviceActionTypes.AppInit;
}

export class DeviceInit implements Action {
  readonly type = DeviceActionTypes.DeviceInit;
}

export class DeviceInitSuccess implements Action {
  readonly type = DeviceActionTypes.DeviceInitSuccess;

  constructor(readonly payload: Devices) {
  }
}

export class DeviceInitFailure implements Action {
  readonly type = DeviceActionTypes.DeviceInitFailure;

  constructor(readonly payload: any) {
  }
}

export class DeviceChangeAction implements Action {
  readonly type = DeviceActionTypes.DeviceChangeAction;

  constructor(readonly payload: Device) {
  }
}

export class DeviceChangeFailure implements Action {
  readonly type = DeviceActionTypes.DeviceChangeFailure;

  constructor(readonly payload: any) {
  }
}
export class DeviceChangeSuccess implements Action {
  readonly type = DeviceActionTypes.DeviceChangeSuccess;

  constructor(readonly payload: any) {
  }
}

export type DeviceActions =
  | AppInit
  | DeviceInit
  | DeviceInitSuccess
  | DeviceInitFailure
  | DeviceChangeAction
  | DeviceChangeSuccess
  | DeviceChangeFailure;
