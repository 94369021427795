import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {config} from '@app/core/smartadmin.config';
import {Observable, of} from 'rxjs';
import {delay, map, catchError, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ApiService {

  exceptUrls = ['/auth/user'];

  constructor(private http: HttpClient) {
  }

  public get(url, params = null): Observable<any> {
    return this.http.get(config.API_URL + url, {params})
      .pipe(
        delay(100),
        // map((result: any) => (result.data || result)),
        catchError((error) => this.handleError(url, error))
      );
  }

  public post(url, body): Observable<any> {
    console.log('this.http.post', config.API_URL + url, body);
    return this.http.post(config.API_URL + url, body)
      .pipe(
        delay(100),
        tap((result: any) => console.log(result)),
        // map((result: any) => (result.data || result)),
        catchError((error) => this.handleError(url, error))
      );
  }

  private handleError(url: string, error: any): Observable<string> {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message

    // console.log(url, error);
    const errMsg = (error.error) ? error.error.message ? error.error.message : error.error :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    throw errMsg;
  }

}


