export interface Entity {
  id: string;
  entityType: string;
}

export interface Profile {
  authority: string;
  createdTime: number;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  id: Entity;
  customerId: Entity;
  ownerId: Entity;
  tenantId: Entity;
  additionalInfo: {
    failedLoginAttempts: number;
    lastLoginTs: number;
    userPasswordHistory: any;
  };
}

export const DefaultProfile: Profile = {
  authority: 'GUEST',
  createdTime: 0,
  firstName: 'Guest',
  lastName: 'Guest',
  name: 'Guest',
  email: 'test@gmail.com',
  id: null,
  customerId: null,
  ownerId: null,
  tenantId: null,
  additionalInfo: null
};

export function createProfile(data: Profile): Profile {
  return {...DefaultProfile, ...data};
}
