import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceName'
})
export class DeviceNamePipe implements PipeTransform {

  transform(value: any, format?: any): any {
    const indexOfStartName = value.indexOf('_');
    return value.substring(indexOfStartName + 1).trim();
  }

}
