<!-- <img src="img/demo/avatars/{{avatar}}" class="profile-image rounded-circle" alt="{{user}}"> -->
<div class="info-card-text">
  <a href="#" class="d-flex align-items-center text-white">
    <span class="text-truncate text-truncate-sm d-inline-block user-name-label">
      {{ (profile$ | async).firstName }} {{ (profile$ | async).lastName }}
    </span>
  </a>
  <span class="d-inline-block text-truncate-sm user-email-label">{{ (profile$ | async).email }}</span>
</div>
<img src="assets/img/card-backgrounds/cover-2-lg.png" class="cover" alt="cover">
<a href="#" onclick="return false;" class="pull-trigger-btn" data-action="toggle" data-class="list-filter-active"
   data-target=".page-sidebar" data-focus="nav_filter_input">
  <i class="{{iconPrefix}} fa-angle-down"></i>
</a>
