

<!-- {{> include/_logo }} -->
<sa-logo></sa-logo>

<!-- BEGIN PRIMARY NAVIGATION -->
<nav id="js-primary-nav" class="primary-nav" role="navigation">
  <sa-nav-filter></sa-nav-filter>
  <sa-nav-info-card class="info-card"></sa-nav-info-card>
  <ul sa-nav id="js-nav-menu" class="nav-menu"></ul>
  <sa-nav-filter-msg></sa-nav-filter-msg>
</nav>
<!-- END PRIMARY NAVIGATION -->

<!-- NAV FOOTER -->
<!-- {{> include/_nav-footer }} -->
<sa-nav-footer class="nav-footer shadow-top"></sa-nav-footer>
<!-- END NAV FOOTER -->


