import * as fromAuth from './auth.reducer';

import {createSelector, createFeatureSelector} from '@ngrx/store';


export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');


export const getAuthLoading = createSelector(getAuthState, (state: fromAuth.AuthState) => state.loading);
export const getAuthError = createSelector(getAuthState, (state: fromAuth.AuthState) => state.error);
export const getUser = createSelector(getAuthState, (state: fromAuth.AuthState) => state.user);
export const getСustomerId = createSelector(getAuthState, (state: fromAuth.AuthState) => state.user ? state.user.customerId.id : null);
export const getToken = createSelector(getAuthState, (state: fromAuth.AuthState) => state.token);

export const getLoggedIn = createSelector(getAuthState, (state: fromAuth.AuthState) => !!state.user);

