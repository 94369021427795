import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'sa-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  logo = 'logo.png';
  appFlavor = 'Prana';
  appFlavorSubscript = '';

  constructor() {
  }

  ngOnInit(): void {
  }

}
