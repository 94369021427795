import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';
import {MetaGuard, MetaModule, MetaService} from '@ngx-meta/core';
import {UnAuthGuard} from '@app/core/guards/un-auth.guard';
import {AuthGuard} from '@app/core/guards/auth.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService} from 'angular-i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';
import {ToastrModule} from 'ngx-toastr';


export function appInit(i18next: ITranslationService) {
  return () => i18next
    .use(i18nextXHRBackend)
    .use(i18nextLanguageDetector)
    .init({
      whitelist: ['en', 'ru', 'ua'],
      fallbackLng: 'en',
      debug: true,
      returnEmptyString: false,
      ns: [
        'translation'
      ],
      interpolation: {
        format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
      },
      backend: {
        loadPath: 'assets/locales/{{lng}}.{{ns}}.json',
      },
      // lang detection plugin options
      detection: {
        // order and from where user language should be detected
        order: ['querystring', 'cookie'],
        // keys or params to lookup language from
        lookupCookie: 'lang',
        lookupQuerystring: 'lng',
        // cache user language on
        caches: ['localStorage', 'cookie'],
        // optional expire and domain for set cookie
        cookieMinutes: 10080, // 7 days
      }
    });
}

export function localeIdFactory(i18next: ITranslationService) {
  console.log('i18next.language', i18next.language);
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    MetaModule.forRoot(),
    I18NextModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true
    })
  ],
  providers: [
    // Guards
    AuthGuard,
    UnAuthGuard,
    I18N_PROVIDERS],
  bootstrap: [AppComponent]
})

export class AppModule {
}

