import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as actions from './device.actions';

import {tap, filter, switchMap, map, catchError, exhaustMap, withLatestFrom} from 'rxjs/operators';

import * as fromAuth from '@app/core/store/auth';

import {of} from 'rxjs';
import {ThingsboardApiService} from '@app/core/services/thingsboard-api.service';
import {DeviceChangeFailure, DeviceChangeSuccess, DeviceInitFailure, DeviceInitSuccess} from './device.actions';
import {Devices, DeviceTelemetry} from '@shared/models/device.model';
import {Store} from '@ngrx/store';

@Injectable()
export class DeviceEffects {

  @Effect()
  deviceInit$ = this.actions$.pipe(
    ofType(actions.DeviceActionTypes.DeviceInit),
    withLatestFrom(this.authStore$.select(fromAuth.getСustomerId)),
    switchMap(([action, customerId]) => {
        return this.thbApi.getUserDevices(customerId).pipe(
          map((devices: Devices) => new DeviceInitSuccess(devices)),
          catchError(error => of(new DeviceInitFailure({error})))
        );
      }
    )
  );

  temperatureSupply = 'temperature_1';
  temperatureInside = 'temperature_3';
  temperatureOutside = 'temperature_2';
  temperature_4 = 'temperature_4';

  @Effect()
  deviceChange$ = this.actions$.pipe(
    ofType(actions.DeviceActionTypes.DeviceChangeAction),
    switchMap(action => {
        console.log('DeviceChangeAction', action);
        return this.thbApi.getDeviceTelemetries(
          Date.now() - 12000,
          Date.now(),
          'AVG',
          [
            this.temperatureInside,
            this.temperatureOutside,
            'humidity',
            this.temperatureSupply,
            'co2',
            'voc',
            'motorsSup',
            'motorsExt',
            'heaterPosition'
          ],
          null, //action.payload.id.id,
          100
        ).pipe(
          map((telemetry: DeviceTelemetry) => new DeviceChangeSuccess(telemetry)),
          catchError(error => of(new DeviceChangeFailure({error})))
        );
      }
    )
  );

  constructor(
    private actions$: Actions,
    private authStore$: Store<fromAuth.AuthState>,
    private thbApi: ThingsboardApiService,
  ) {

  }
}
