<div class="d-flex align-items-center flex-1 text-muted">
  <span class="hidden-md-down fw-700">{{copyright}}</span>
</div>
<div>
  <ul class="list-table m-0">
    <li> </li>
    <!--<li><a href="intel_introduction.html" class="text-secondary fw-700">About</a></li>-->
    <!--<li class="pl-3"><a href="info_app_licensing.html" class="text-secondary fw-700">License</a></li>-->
    <!--<li class="pl-3"><a href="info_app_docs.html" class="text-secondary fw-700">Documentation</a></li>-->
    <!--<li class="pl-3 fs-xl"><a href="https://wrapbootstrap.com/user/MyOrange" class="text-secondary" target="_blank"><i class="{{iconPrefix}} fa-question-circle" aria-hidden="true"></i></a></li>-->
  </ul>
</div>
