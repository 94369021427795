import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import {DeviceNamePipe} from '@shared/pipes/device-name.pipe';
import {SortByPipe} from '@shared/pipes/sort-by.pipe';
import {WithParentGroupPipe} from '@shared/pipes/with-parent-group.pipe';
import {FilterByAttrPipe} from '@shared/pipes/filter-by-attr.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FieldFilterPipe, MomentPipe, DeviceNamePipe, SortByPipe, WithParentGroupPipe, FilterByAttrPipe],
  exports: [FieldFilterPipe, MomentPipe, DeviceNamePipe, SortByPipe, WithParentGroupPipe, FilterByAttrPipe],
  providers: [DeviceNamePipe]
})
export class PipesModule { }
