import {Injectable} from '@angular/core';
import {AuthResponseDto} from '@shared/models/auth.dto';
import { Store} from '@ngrx/store';
import * as fromAuth from '../store/auth';
import {AuthInit, AuthState, AuthTokenPayload, LogoutAction} from '../store/auth';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  token = localStorage.getItem('token');
  refreshToken = localStorage.getItem('refreshToken');

  constructor(
    private store: Store<AuthState>
  ) {
  }

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(new AuthInit());

      const payload: AuthResponseDto = {
        token: this.token,
        refreshToken: this.refreshToken
      };

      this.store.select(fromAuth.getAuthLoading).subscribe(status => {
        console.log('getAuthLoading', status);
        if (!status) {
          resolve(true);
        }
      });

      if (payload.token) {
        this.store.dispatch(new AuthTokenPayload(payload));
        console.log('init.resolve', true);
      } else {
        this.store.dispatch(new LogoutAction( false));
        console.log('init.resolve', false);
      }
    });
  }

}

export function AuthFactory(appService: AppService): () => Promise<boolean> {
  return () => appService.init();
}
