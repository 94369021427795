import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[sa-footer]',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  copyright = '2020 © Prana by https://prana.com.ua';
  iconPrefix = 'fal';

  constructor() {}

  ngOnInit() {}

}
