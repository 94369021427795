import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {SmartadminLayoutModule} from './layout';

import {I18nModule} from './i18n/i18n.module';
import {UserModule} from './user/user.module';
import {BootstrapModule} from '@app/shared/bootstrap.module';
import {VoiceControlModule} from './voice-control/voice-control.module';

import {UtilsModule} from './utils/utils.module';
import {PipesModule} from './pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SmartadminLayoutModule,
    BootstrapModule
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UserModule,
    SmartadminLayoutModule,
    BootstrapModule,
    I18nModule,
    UtilsModule,
    PipesModule,
    VoiceControlModule,
  ]
})
export class SharedModule {
}
