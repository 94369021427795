import {ActivatedRouteSnapshot, CanLoad, Resolve, Route, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '@app/core/services';
import {Observable, of} from 'rxjs';
import {State, Store} from '@ngrx/store';
import {AuthState, LogoutAction} from '@app/core/store/auth';

@Injectable({providedIn: 'root'})
export class LogoutGuard implements CanLoad {
  constructor(
    private auth: AuthService,
    private store: Store<AuthState>
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    console.log('LogoutGuard');

    this.store.dispatch(new LogoutAction());
    return of(false);
  }
}
